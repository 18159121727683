<template>
  <div v-if="showStockCheckSheetImportExcel">
    <KTCodePreview v-bind:title="'Nhập file Excel vào phiếu kiểm tra tồn kho'">
      <template v-slot:preview>
        <b-form ref="form" class="row">
          <!-- Input session -->
          <b-container class="bv-example-row">
            <b-row>
              <b-col>
                <div class="form-group">
                  <span>
                    <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                    đúng format, hoặc có thể tải ở <a :href="urlExcel">đây</a>
                  </span>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <span> <b>Bước 2:</b> Tiến hành Import </span>
                <div style="padding-top: 15px; display: flex">
                  <b-form-file
                    style="display: none"
                    placeholder="Hãy chọn một tập tin"
                    @change="selectFile"
                    v-model="file"
                    ref="file-input"
                    class="mb-2"
                    id="form-import"
                  ></b-form-file>
                  <b-form-input
                    type="text"
                    v-model="textFile"
                    placeholder="Hãy chọn một tập tin"
                    @click="onOpenFile('form-import')"
                    v-on:keydown.prevent
                    style="caret-color: transparent; cursor: pointer"
                    autocomplete="off"
                  ></b-form-input>
                  <b-button
                    variant="secondary"
                    :disabled="!file"
                    v-b-tooltip.hover
                    @click="uploadFile"
                    style="height: 39px; width: 4%"
                    title="Tải file"
                    ref="up_excel"
                  >
                    <i class="fas fa-upload text-primary"></i>
                  </b-button>
                  <b-button
                    style="height: 100%; width: 4%"
                    variant="secondary"
                    :disabled="!file"
                    @click="onClearFiles"
                    v-b-tooltip.hover
                    title="Xoá file"
                    ><i class="fas fa-trash text-danger"></i
                  ></b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <!-- End input session -->
          <!-- Show status -->
          <b-container class="mt-10">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="selectedStatus"
                @change="onChangeStatus()"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
              >
                <b-form-radio value="all"
                  >Tất cả
                  <span class="text-primary"
                    >({{ totalImportItem }})</span
                  ></b-form-radio
                >
                <b-form-radio value="valid"
                  >Hợp lệ
                  <span class="text-success"
                    >({{ countValid }})</span
                  ></b-form-radio
                >
                <b-form-radio value="error"
                  >Lỗi
                  <span class="text-danger"
                    >({{ countInValid }})</span
                  ></b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
            <b-table
              class="myTable"
              bordered
              hover
              :fields="fields"
              :items="importItems"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template v-slot:cell(isValid)="row">
                <span
                  v-text="getStatusName(row.item)"
                  class="label font-weight-bold label-lg label-inline"
                  v-bind:class="getStatusClass(row.item)"
                >
                </span>
              </template>
              <template v-slot:cell(message)="row">
                <span v-if="!row.item.isValid">
                  {{ row.item.message }}
                </span>
              </template>
              <template v-slot:cell(delete)="row">
                <b-button
                  class="no-background-btn"
                  variant="secondary"
                  @click.prevent="deleteItem(row.item)"
                  title="Xoá"
                  ><i class="fas fa-trash text-danger"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              v-show="totalImportItem >= perPage"
              v-model="currentPage"
              :total-rows="totalImportItem"
              :per-page="perPage"
              aria-controls="my-table-product"
              align="right"
            ></b-pagination>
          </b-container>

          <b-container
            class="text-dark font-weight-bold"
            v-show="totalImportItem > 0"
          >
            <p>Tổng số: {{ totalImportItem }}</p>
          </b-container>
        </b-form>
      </template>
      <template v-slot:foot>
        <div class="bv-example-row container">
          <b-row>
            <b-button
              style="font-weight: 600; width: 70px"
              :disabled="!uploading"
              variant="primary"
              size="sm"
              @click="importInventoryCheck"
              >Lưu
            </b-button>
            <router-link to="/inventory-check" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy
              </b-button>
            </router-link>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
.table th,
.table td {
  text-align: center;
}

.myTable /deep/ .table {
  width: max-content !important;
  color: #464e5f;
  background-color: transparent;
}
.no-background-btn {
  background-color: transparent;
  border: none;
  padding: 0;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import axios from 'axios';
import {
  cloneDeep,
  makeToastFaile,
  makeToastSuccess,
  handleError,
} from '@/utils/common';
import { v4 as uuidv4 } from 'uuid';
import { URL_PRODUCTS_IMPORT_INVENTORY_CHECK } from './../../../utils/constants';
import { isFeatureActive } from '../../../utils/common';

export default {
  data() {
    return {
      tmpImportItems: [],
      selectedStatus: 'all',
      currentPage: 1,
      perPage: 10,
      textFile: '',
      urlExcel: URL_PRODUCTS_IMPORT_INVENTORY_CHECK,
      fields: [
        {
          key: 'productId',
          label: 'ID',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
        {
          key: 'imeiNo',
          label: 'IMEI',
        },
        {
          key: 'storeName',
          label: 'Tên cửa hàng',
        },
        {
          key: 'imeiStatusName',
          label: 'Trạng thái IMEI',
        },
        {
          key: 'isValid',
          label: 'Trạng thái',
          thStyle: { width: '10%' },
        },
        {
          key: 'message',
          label: 'Thông báo',
          thStyle: { width: '10%' },
          visible: true,
        },
        {
          key: 'delete',
          label: '',
          visible: true,
        },
      ],
      importItems: [],
      file: null,
      excellist: [],
      progress: 0,
      uploading: false,
      showStockCheckSheetImportExcel: false,
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách phiếu kiểm tra tồn kho', route: '/inventory-check' },
      {
        title: 'Nhập Excel phiếu kiểm tra tồn kho',
        route: '/import-inventory-check',
      },
    ]);
  },
  computed: {
    totalImportItem() {
      return this.tmpImportItems.length;
    },
    countValid() {
      return this.tmpImportItems.filter((item) => item.isValid).length;
    },
    countInValid() {
      return this.tmpImportItems.filter((item) => !item.isValid).length;
    },
    getStatusName() {
      return (objItem) => {
        if (!objItem.isValid) return 'Lỗi';
        return 'Hợp lệ';
      };
    },
    getStatusClass() {
      return (objItem) => {
        if (!objItem.isValid) return 'label-light-danger';
        return 'label-light-success';
      };
    },
  },
  methods: {
    onOpenFile(name) {
      document.getElementById(name).click();
    },
    uploadFile() {
      const submitButton = this.$refs['up_excel'];
      this.$refs['up_excel'].innerHTML = '';
      submitButton.classList.add(
        'spinner',
        'spinner-primary',
        'spinner-md',
        'spinner-center',
        'px-7',
      );
      this.onValidateDataExcel();
    },
    onClearFiles() {
      this.file = null;
      this.importItems = [];
      this.tmpImportItems = [];
      this.textFile = '';
      this.$refs['file-input'].reset();
    },
    onChangeStatus() {
      switch (this.selectedStatus) {
        case 'all':
          this.importItems = cloneDeep(this.tmpImportItems);
          break;
        case 'valid':
          this.importItems = this.tmpImportItems.filter((item) => item.isValid);
          break;
        case 'error':
          this.importItems = this.tmpImportItems.filter(
            (item) => !item.isValid,
          );
          break;
      }
    },

    onValidateDataExcel() {
      this.uploading = true;
      if (!this.file) {
        return;
      }
      const submitButton = this.$refs['up_excel'];
      let formData = new FormData();
      const file = this.file;
      formData.append('files', file);
      const idempotenceKey = uuidv4();
      const config = {
        headers: {
          'x-idempotence-key': idempotenceKey,
        },
        onUploadProgress: (progressEvent) => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };
      axios
        .post(`v2/stock-check-sheet/import-excel`, formData, config)
        .then((response) => {
          this.$refs['up_excel'].innerHTML =
            '<i class="fas fa-upload text-primary"></i>';
          submitButton.classList.remove(
            'spinner',
            'spinner-primary',
            'spinner-md',
            'spinner-center',
            'px-7',
          );
          if (response.data.status === 0) {
            return makeToastFaile(response.data.message);
          } else {
            const data = response.data.data.rows;

            this.importItems = data;
            this.tmpImportItems = data;

            makeToastSuccess(response.data.message);
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs['up_excel'].innerHTML =
              '<i class="fas fa-upload text-primary"></i>';
            this.$nprogress.done();
            submitButton.classList.remove(
              'spinner',
              'spinner-primary',
              'spinner-md',
              'spinner-center',
              'px-7',
            );
            handleError(error);
          }
        });
    },
    importInventoryCheck: async function () {
      this.uploading = true;
      if (!this.file || !this.importItems.length) {
        return;
      }
      const body = {
        storeId: this.importItems[0].storeId,
        stockCheckProducts: this.importItems.map((item) => ({
          imeiNo: item.imeiNo,
        })),
      };

      const inValidItems = this.importItems.filter((item) => !item.isValid);
      if (inValidItems.length) {
        return makeToastFaile(
          'Vui lòng nhập dữ liệu hợp lệ, xem danh sách lỗi!',
        );
      }

      const idempotenceKey = uuidv4();
      const config = {
        headers: {
          'x-idempotence-key': idempotenceKey,
        },
      };
      axios
        .post('v2/stock-check-sheet/create-excel', body, config)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 200) {
            this.clearFiles();
            this.$router.push({
              name: 'list-inventory-check',
            });
            makeToastSuccess(message);
          }
          this.uploading = false;
        })
        .catch((error) => {
          handleError(error);
          this.uploading = false;
        });
    },
    selectFile(event) {
      const files = event.target.files[0];
      this.file = files;
      this.textFile = files.name;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return makeToastFaile(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx',
        );
      }
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    deleteItem(item) {
      const index = this.importItems.indexOf(item);
      if (index !== -1) {
        this.importItems.splice(index, 1);

        this.tmpImportItems = this.tmpImportItems.filter((i) => i !== item);

        makeToastSuccess('Phần tử đã được xóa thành công!');
      } else {
        makeToastFaile('Phần tử không tồn tại!');
      }
    },
  },
  async created() {
    this.showStockCheckSheetImportExcel = await isFeatureActive(
      'stock-check-sheet-import-excel',
    );
  },
};
</script>
